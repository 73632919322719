const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
  }
  
  const masterBreakdownCategoryData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_MASTER_BREAKDOWN_CATEGORY':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_MASTER_BREAKDOWN_CATEGORY':
        return { ...state }
      case 'UPDATE_MASTER_BREAKDOWN_CATEGORY':
        return { ...state }
      default:
        return { ...state }
    }
  }
  
  export default masterBreakdownCategoryData
  