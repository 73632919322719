const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  firmData: [],
  siteData: []
}

const contactPersonMasterData = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_CONTACT_PERSON':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_CONTACT_PERSON':
      return { ...state }
    case 'UPDATE_CONTACT_PERSON':
      return { ...state }
    case 'GET_DATA_FIRM':
      return {
        ...state,
        firmData: action.firmData
      }
    case 'GET_DATA_SITE':
      return {
        ...state,
        siteData: action.siteData
      }
    default:
      return { ...state }
  }
}

export default contactPersonMasterData
