const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData:[],
    jobReportDataByID:[],
    materialAndSpecificationInfoById:[]
   
    }
  
  const jobReportData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_JOB_REPORT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        }
        case 'GET_CITY_LIST':
          return { 
            ...state,
            cityListData: action.cityListData
          }
          case 'GET_JOB_REPORT_DATA':
            return { 
              ...state,
              jobReportDataByID: action.jobReportDataByID
            }

            case 'GET_MATERIAL_AND_SPECIFICATION_DATA':
              return { 
                ...state,
                materialAndSpecificationInfoById: action.data
              }
      
      default:
        return { ...state }
    }
  }
  export default jobReportData
  