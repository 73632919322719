// **  Initial State
const initialState = {
  userData: {},
  isPasswordExist: false,

}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        isPasswordExist: action.isPasswordExist,
        // Token : sessionStorage.getItem('id_token'),
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }

    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }

      case 'CHANGE_PASSWORD':
      return { ...state, ...action }

      case 'SET_IS_PASSWORD_EXIST':
      return {
        ...state,
        isPasswordExist: action.isPasswordExist,
      };
      default:
      return state
  }
}

export default authReducer
