const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityManagerData:[]
    }
  
  const mailTriggerReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_MAIL':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'CREATE_MAIL_TRIGGER':
        return { ...state }
        case 'UPDATE_MAIL_TRIGGER':
          return { ...state }
    
        case 'GET_CITY_MANAGER':
          return { 
            ...state,
            cityManagerData: action.cityManagerData
          }
      default:
        return { ...state }
    }
  }
  export default mailTriggerReducer
  