const initialState = {
    data: [],
    }
  
  const settingMasterDataReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_SETTING':
        return {
          ...state,
          data: action.data,
        }
      case 'CREATE_SETTING':
        return { ...state }
      default:
        return { ...state }
    }
  }
  export default settingMasterDataReducer
  