// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './master/userMasterReduser'
import cityMasterData from './master/cityMasterReducer'
import passengerMasterData from './master/passengerMasterReducer'
import settingMasterData from './master/settingMasterReducer'

import profile from './profile'
import specialFeatureMasterData from './master/specialFeatureMasterDataReducer'
import landingDoorMasterData from './master/landingDoorMasterDataReducer'
import carDoorMasterData from './master/carDoorMasterDataReducer'
import typeOfLiftMasterData from './master/typeOfLiftReducer'
import copMasterData from './master/copMasterReducer'
import cabinMasterData from './master/cabinMasterReducer'
import masterMachineData from './master/machineMasterDataReducer '
import masterDesignationData from './master/designationMasterDataReducer'
import masterBreakdownCategoryData from './master/breakdownCategoryMasterReducer'
import breakdownReportData from './master/breakdownReportMasterReducer'
import breakdownIssueData from './master/breakdownIssueMasterReducer'
import speedMasterData from './master/speedMasterReducer'
import materialCategoryMasterData from './master/materialCategoryMasterDataReducer'
import materialMasterData from './master/materialMasterDataReducer'
import departmentMasterData from './master/departmentMasterDataReducer'
import firmMasterData from './master/firmMasterDataReducer'
import siteMasterData from './master/siteMasterDataReducer'
import contactPersonMasterData from './master/contactPersonMasterDataReducer'
import amcInspectionChecklistMasterData from './master/amcInspectionChecklistMasterDataReducer'
import payemenTermMasterData from './master/payemenTermMasterReducer'
import cabinFormData from './forms/cabinFormReducer'
import carCounterMasterData from './forms/carCounterMasterDataReducer'
import componentMasterData from './forms/componentMasterDataReducer'
import formInformationMasterData from './master/formInfoMasterReducer'
import machineFormData from './forms/machineFormReducer'
import InfoData from './master/infoReducer'
import ardFormData from './forms/ardFormDataReducer'
import leadReport from './report/leadReportReducer'
import amcReportData from './report/amcReportReducerData'
import jobReportData from './report/jobReportReducer'
import dashboardData from './dashboard/dashboardDataReducer'
// import wiringFormData from './master/wiringFormReducerData'
import amcInspectionIssueData from './master/amcInspectionIssueMasterReducer'
import mailTriggerReducer from './mailTrigger/mailTriggerReducer'
import settingData from './settingForWeb/settingReducer'
import lostReasonMasterData from "./master/lostReasonMasterReducer"
import followupRemarkMasterData from "./master/followupRemarkMasterReducer"
import leadSourceMasterData from "./master/leadSourceMasterReducer"
import userTrackingData from './tracking/userTracking'
import locationTracking from './tracking/locationTracking'
import superAdminConfigreducer from './superAdminConfig/superAdminConfigReducer'

const rootReducer = combineReducers({
  auth,
  profile,
  navbar,
  layout,
  users,
  cityMasterData,
  passengerMasterData,
  settingMasterData,
  specialFeatureMasterData,
  landingDoorMasterData,
  carDoorMasterData,
  typeOfLiftMasterData,
  copMasterData,
  cabinMasterData,
  masterMachineData,
  masterDesignationData,
  masterBreakdownCategoryData,
  breakdownReportData,
  breakdownIssueData,
  speedMasterData,
  materialCategoryMasterData,
  materialMasterData,
  departmentMasterData,
  firmMasterData,
  siteMasterData,
  contactPersonMasterData,
  amcInspectionChecklistMasterData,
  payemenTermMasterData,
  cabinFormData,
  carCounterMasterData,
  componentMasterData,
  formInformationMasterData,
  machineFormData,
  ardFormData,
  InfoData,
  leadReport,
  amcReportData,
  jobReportData,
  dashboardData,
  amcInspectionIssueData,
  mailTriggerReducer,
  settingData,
  lostReasonMasterData,
  followupRemarkMasterData,
  leadSourceMasterData,
  userTrackingData,
  locationTracking,
  userTrackingData,
  locationTracking,
  lostReasonMasterData,
  followupRemarkMasterData,
  leadSourceMasterData,
  superAdminConfigreducer,

})

export default rootReducer
