const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    breakDownCategoryData:[]
    
  }
  
  const breakdownIssueData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA_BREAKDOWN_ISSUE':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          breakDownCategoryData:[]
        }
      case 'CREATE_BREAKDOWN_ISSUE':
        return { ...state }
      case 'UPDATE_BREAKDOWN_ISSUE':
        return { ...state }
        case 'GET_BREAKDOWN_CATEGORY':
          return { 
            ...state,
            breakDownCategoryData: action.breakDownCategoryData
          }
      default:
        return { ...state }
    }
  }
  
  export default breakdownIssueData
  