const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData:[],
    leadReportData:[],
   
    }
  
  const leadReport = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_LEAD_REPORT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        }
        case 'GET_CITY_LIST':
          return { 
            ...state,
            cityListData: action.cityListData
          }
          case 'GET_REPORT_DATA':
          return { 
            ...state,
            leadReportData: action.leadReportData
          }
          
      
      default:
        return { ...state }
    }
  }
  export default leadReport
  