const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cabinData:[],
    getPassengerData:[]
    }
  
  const cabinFormData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_CABIN':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          cabinData:[]
        }
        case 'CREATE_CABIN':
        return { ...state }
        case 'UPDATE_CABIN':
          return { ...state }
          case 'UPDATE_CABIN':
            return { ...state,  cabinData: action.cabinData}
            case 'GET_DATA_CABIN_MASTER':
              return { ...state, cabinData:action.cabinData }
              case 'GET_DATA_PASSENGER':
                return { ...state, getPassengerData:action.getPassengerData }
      default:
        return { ...state }
    }
  }
  export default cabinFormData
  