const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData:[],
    amcReportDataByID:[]

   
    }
  
  const amcReportData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_AMC_REPORT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        }
        case 'GET_CITY_LIST':
          return { 
            ...state,
            cityListData: action.cityListData
          }
          case 'GET_AMC_REPORT_DATA':
            return { 
              ...state,
              amcReportDataByID: action.amcReportDataByID
            }
      
      default:
        return { ...state }
    }
  }
  export default amcReportData
  