const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  designationData: [],
  reportingManager:[],
  appUserAccess:[],
  // change_PasswordData:[],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_USER':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_USER':
      return { ...state }
    case 'UPDATE_USER':
      return { ...state }
    case 'DELETE_INVOICE':
      return { ...state }
    case 'GET_DESIGNATION':
      return {
        ...state,
        designationData: action.designationData
      }
    case 'GET_MANAGER':
      return {
        ...state,
        reportingManager: action.data
      }
    case 'GET_DEPARTMENT':
      return {
        ...state,
        departmentData: action.data
      }
      case 'GET_USER_ACCESS':
      return {
        ...state,
        userAccess: action.data
      }
      case 'GET_APP_USER_ACCESS':
        return {
          ...state,
          appUserAccess: action.data
        }
      case 'CHANGE_PASS':
        return {
          ...state,
        }
        case 'SET_PASSWORD':
        return { ...state}
    default:
      return { ...state }
  }
}
export default userReducer
