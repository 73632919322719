// ** Checks if an object is empty (returns boolean)
import axios from 'axios'
import { firebase, auth } from "./../base";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import AWS from 'aws-sdk';
import { Spinner } from 'reactstrap'

import { useState } from 'react'
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === '123123') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'Your session has been expired',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonText: 'Yes, logout!',
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-outline-danger ml-1'
    },
    allowOutsideClick: false,
    buttonsStyling: false
  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}


// getAxiosInstance() async{

// }

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  response => response,
  error => {

    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();

    } else {

      return Promise.reject(error);
    }

  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const SuperAdminAxios = axios.create({
  baseURL: process.env.REACT_APP_SUPER_ADMIN_API_URL,
})

export { Axios, AxiosInstance, SuperAdminAxios }

//-------------------image upload on s3--------------------------//
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION
});

const s3 = new AWS.S3({
  apiVersion: '2012-10-17',
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME }
});

export const uploadImageToS3 = (file) => {
  const params = {
    Key: `${Date.now()}_${file.name}`,
    Body: file,
    // ACL: 'public-read',
    ContentType: file.type
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        resolve("");
      } else {
        resolve(data.Location);
      }
    });
  });
};
export const deleteImageFromS3 = (filepath) => {
  const params = {
    Key: filepath
  };

  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (err, data) => {
      if (err) {
        resolve("");
      } else {
        resolve(data);
      }
    });
  });
};

export const CustomLoader = () => {
  return (<div style={{ position: "fixed", top: "60%", left: "60%" }}>
    <Spinner color="primary" ></Spinner>
    <h5>Loading...</h5> </div>)
}


//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};


//restrict Negative values and allow only number
export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9)
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
  if (event.target.value === '' && charCode === 45) {
    return;
  }
  if (charCode === 45) {
    event.preventDefault();
  }
};

