const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityData: []
    }
  
  const firmMasterData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_FIRM':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'CREATE_FIRM':
        return { ...state, 
          cityData: action.cityData }
        case 'UPDATE_FIRM':
          return { ...state, 
            cityData: action.cityData }
          case 'GET_DATA_CITY':
            return { 
              ...state,
              cityData: action.cityData
            }
      default:
        return { ...state }
    }
  }
  export default firmMasterData
  