// **  Initial State
import app from './../../../base'
const initialState = {
  userData: {}
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'PROFILE_REGISTER':
        return {
          ...state,
          profiledata: action.data
          // Token : sessionStorage.getItem('id_token'),
          // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
          // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
        }
    default:
      return state
  }
}

export default profileReducer