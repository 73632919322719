const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    getDataByUrl:[]
    }
  
  const formInformationMasterData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_INFORMATION':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'CREATE_INFORMATION':
        return { ...state }
        case 'UPDATE_INFORMATION':
          return { ...state }
        case 'GET_DATA_BY_URL':
            return { ...state }
        
      default:
        return { ...state }
    }
  }
  export default formInformationMasterData
  